import * as React from "react"
import MainLayout from "../layouts/MainLayout"

import SEO from '../components/SEO'
import Page from '../components/Page'
import { graphql } from "gatsby"
import Hero from "../components/videos/Hero"

export default function TrailerVideoPage ({data}) {
  return (
      <MainLayout>
        <SEO title={data.strapiPages.Trailer. Seo.Title} description={data.strapiPages.Trailer.Seo.Description} />
        <Hero sources={data.strapiPages.Trailer} />
        <Page data={data.strapiPages.Trailer} />
      </MainLayout>
  )
}


export const pageQuery = graphql`  
query($slug: String!) {
  strapiPages(Slug: { eq: $slug }) {
    Trailer {
      Description
      Slug
      Title
      Date
      YoutubeCode
      Seo {
        Description
        Keywords
        Title
      }
      VideoThumbnail {
        localFile {
          publicURL
        }
      }
      Image {
        localFile {
          publicURL
        }
      }
    }
  }
  }
`
