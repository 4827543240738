import React from "react"
import iconPlay from "../../assets/images/icon-play.svg"

const isElementVideo = $el => {
    const name = $el.tagName.toLowerCase()
    const isVideo = name === "iframe"
    return isVideo
}

const Hero = ({ sources }) => {
    const handlePlayClick = e => {
        const $img = e.target
        const $mediaImg = $img.nextSibling
        const $video = $mediaImg.nextSibling
        const shouldDisplayVideo = isElementVideo($video)

        if (shouldDisplayVideo) {
            const $elementsWillBeHidden = [$img, $mediaImg]
            $video.style.height = `${$mediaImg.height}px`

            $elementsWillBeHidden.forEach(i => {
                i.hidden = true
                i.style = "border:none;"
            })

            $video.src = $video.dataset.src
            $video.hidden = false
        }
    }
    return (
        <section className="video-hero">
            <div className="banner-img">
                <div className="media-sources">
                    <img
                        src={iconPlay}
                        alt="video-play"
                        width="108"
                        height="108"
                        className="icon-play"
                        role="presentation"
                        onClick={handlePlayClick}
                        onKeyDown={handlePlayClick}
                    />
                    <div
                        style={{
                            backgroundImage: `url('${sources.VideoThumbnail.localFile.publicURL}')`,
                            maxWidth: '1064px',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            display: 'flex'
                        }}
                        alt={"Test"}
                        className="media-img"
                    />
                    <iframe
                        title="youtube-video-iframe"
                        style={{ maxWidth: '1064px' }}
                        height="80%"
                        data-src={`https://www.youtube.com/embed/${sources.YoutubeCode}?autoplay=1&rel=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        hidden
                    ></iframe>
                </div>
            </div>
        </section>
    )
}

export default Hero
